import { getImage as gatsbyGetImage } from "gatsby-plugin-image";

const getImage = (image, defaultImage = null) => {
  if (!image?.localFile)
    return gatsbyGetImage(
      defaultImage?.localFile?.childImageSharp?.gatsbyImageData
    );
  if (typeof image === "string") return image;
  if (image.localFile.childImageSharp)
    return gatsbyGetImage(image.localFile.childImageSharp.gatsbyImageData);
  return image.localFile.publicURL;
};

export default getImage;
