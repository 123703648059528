import React from "react";
import { Box, Button, Grid, Text } from "theme-ui";
import Tile from "./Tile";

const TileList = ({
  vendorList,
  loadedData,
  setLoadedData,
  totalCount,
  ...props
}) => {
  return (
    <Box {...props}>
      <Grid
        gap={5}
        columns={["1fr", "1fr 1fr"]}
        sx={{
          justifyContent: "center",
          my: 6,
        }}
      >
        {vendorList.map((vendor) => (
          <Tile key={`tile-list-${vendor.id}`} element={vendor} />
        ))}
      </Grid>

      {totalCount - loadedData > 0 && (
        <Box
          sx={{
            my: 6,
            color: "primary",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button variant="text" onClick={() => setLoadedData(loadedData + 10)}>
            Load more (
            {totalCount - loadedData > 0 ? totalCount - loadedData : 0})
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default TileList;
