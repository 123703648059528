import React from "react";
import { Box, Flex } from "theme-ui";
import DatePickerHeader from "./DatePicker/DatePickerHeader";
import Calendar from "./DatePicker/Calendar";
import CalendarProvider from "./DatePicker/CalendarContext";

const DoubleDatePickerField = ({ disabledPrevDays, input, ...props }) => {
  return (
    <CalendarProvider double input={input} {...props}>
      <Flex
        sx={{
          justifyContent: "center",
          alignItems: "stretch",
          flexWrap: "wrap",
          bg: "componentBG2",
          gap: 2,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <Box>
          <DatePickerHeader side="left" />
          <Calendar disabledPrevDays={disabledPrevDays} />
        </Box>
        <Box sx={{ border: "1px solid", borderColor: "lineDetail" }} />
        <Box>
          <DatePickerHeader side="right" moveMonth={1} />
          <Calendar disabledPrevDays={disabledPrevDays} moveMonth={1} />
        </Box>
      </Flex>
    </CalendarProvider>
  );
};

export default DoubleDatePickerField;
