import {
  areIntervalsOverlapping,
  isAfter,
  isBefore,
  startOfToday,
} from "date-fns";

function isBetween(from, to) {
  if (!from && !to) return true;

  if (from && !to) return isAfter(startOfToday(), new Date(from));

  if (!from && to) return isBefore(startOfToday(), new Date(to));

  return (
    isAfter(startOfToday(), new Date(from)) &&
    isBefore(startOfToday(), new Date(to))
  );
}

export function filterByLimitedTime(vendor) {
  const { displayFrom, expiresAt } = vendor;

  return isBetween(displayFrom, expiresAt);
}

export function filterByExperienceLimit(from, to) {
  return (vendor) => {
    if (!from || !to) return true;

    const { startsOn, endsOn } = vendor;
    if (!startsOn || !endsOn) return true;

    try {
      return areIntervalsOverlapping(
        {
          start: new Date(from),
          end: new Date(to),
        },
        {
          start: new Date(startsOn),
          end: new Date(endsOn),
        }
      );
    } catch (_) {
      return true;
    }
  };
}

export const filterByMatchingVendorTags =
  (currentVendor, properties) => (vendor) => {
    return (
      properties.includes(vendor.propertyId) &&
      vendor.tags.findIndex((current) =>
        currentVendor.tags.map(({ id }) => id).includes(current.id)
      ) !== -1
    );
  };
