export const filterVendorListByTagTitle = (allVendorList, tag) => {
  const filteredResults = [];
  allVendorList.forEach((option) => {
    option.tags.forEach((vendorTag) => {
      if (vendorTag.title.includes(tag)) {
        filteredResults.push(option);
      }
    });
  });
  return filteredResults;
};

export const filterVendorListByTagId = (allVendorTags, tagId) => {
  const filteredResults = [];
  allVendorTags.forEach((option) => {
    option.tags.forEach((vendorTag) => {
      if (vendorTag.id === tagId) {
        filteredResults.push(option);
      }
    });
  });
  return filteredResults;
};
