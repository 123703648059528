import React, { useContext, useEffect } from "react";
import { Box, IconButton, Input, Text } from "theme-ui";
import { format, isValid } from "date-fns";
import Frame from "../Frame";
import { CalendarContext } from "./CalendarContext";
import { DateRange, Search } from "@material-ui/icons";

const DATE_FORMAT = "iii, d LLL";

function formatDate(date, defValue) {
  if (!date && !isValid(date)) return defValue;
  try {
    return format(date, DATE_FORMAT);
  } catch {
    return defValue;
  }
}

const FilterInput = ({ name, label, onSearch, rightIcon, ...props }) => {
  const { selectedRange, isSelecting } = useContext(CalendarContext);

  const { start, end } = selectedRange;

  useEffect(() => {
    if (!isSelecting) {
      onSearch(selectedRange);
    }
  }, [start, end, isSelecting]);

  return (
    <Frame name={name} label={label} {...props}>
      <Box
        htmlFor={name}
        sx={{
          variant: "forms.input",
          display: "flex",
          alignItems: "center",
          gap: 2,
          color: "text.secondary",
        }}
      >
        <Box sx={{ display: "inline-flex", fontSize: 6, color: "primary" }}>
          <DateRange />
        </Box>
        <Text
          variant="body1"
          sx={{ flex: 1, color: start ? "text" : "text.secondary" }}
        >
          {formatDate(start, "Start date")}
        </Text>
        <Text variant="body1" sx={{ display: "inline-block", px: 2 }}>
          -
        </Text>
        <Text
          variant="body1"
          sx={{ flex: 1, color: start ? "text" : "text.secondary" }}
        >
          {formatDate(end, "End date")}
        </Text>
        {rightIcon && (
          <IconButton
            variant="primary"
            sx={{
              fontSize: 6,
              width: 10,
              height: 10,
              borderRadius: 1,
            }}
          >
            <Search />
          </IconButton>
        )}
      </Box>
      <Input
        id={name}
        type="hidden"
        className={start && end ? "withValue" : ""}
      />
    </Frame>
  );
};

export default FilterInput;
