import React from "react";
import { navigate } from "gatsby";
import { Box, Paragraph } from "theme-ui";
import Card from "../../components/card/Card";
import { useVariantByBreakpoint } from "../../utils";
import LimitedLines from "components/LimitedLines";
import { vendorPageUrl } from "utils/urls";

const Tile = ({ element }) => {
  const variantSelector = useVariantByBreakpoint();

  return (
    <Card
      type="white"
      orientation="landscape"
      size={variantSelector(["large", null, "extra_large"])}
      image={element.image}
      sx={{ height: 56 }}
      contentSx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      onClick={() => {
        navigate(vendorPageUrl(element));
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box>
          <Paragraph variant={variantSelector(["subtitle1", null, "heading6"])}>
            {element.name}
          </Paragraph>
          {element.description && (
            <LimitedLines
              variant={variantSelector(["body2", null, "body1"])}
              lines={3}
              sx={{
                my: 5,
              }}
            >
              {element.description}
            </LimitedLines>
          )}
        </Box>
        {element.contactInfo && (
          <Box>
            {element.contactInfo.address && (
              <Box
                sx={{
                  display: "flex",
                  whiteSpace: "nowrap",
                  color: "text.secondary",
                }}
              >
                <Paragraph
                  variant={variantSelector(["body3", "body2"])}
                  sx={{ overflow: "hidden" }}
                >
                  {element.contactInfo.address.address_line_1}
                </Paragraph>
                {element.contactInfo.address.city && (
                  <Paragraph
                    variant={variantSelector(["body3", "body2"])}
                    sx={{ overflow: "hidden" }}
                  >
                    , {element.contactInfo.address.city}
                  </Paragraph>
                )}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default Tile;
