import React, { useState, useMemo, useEffect } from "react";
import { Box, Heading, Paragraph } from "theme-ui";
import Layout from "components/Layout";
import Link from "../components/Link";
import TagSlider from "vendors/components/TagSlider";
import { useVariantByBreakpoint } from "../utils";
import SortIcon from "@material-ui/icons/Sort";
import { filterVendorListByTagId } from "../utils/filterVendorListByTag";
import { navigate } from "gatsby";
import TileList from "./components/TileList";
import { stringFormatForUrl } from "../utils/stringFormat";
import DoubleDatePickerField from "components/Fields/DoubleDatePicker";
import FilterInput from "components/Fields/DatePicker/FilterInput";
import { filterByExperienceLimit } from "utils/filterBy";
import { cityPageUrl, experiencePageUrl } from "utils/urls";
import { formatDateForCalendar } from "components/Fields/DatePicker/CalendarContext";

const PAGE_NAME = "Explore";

const ExperiencesPage = ({
  strapiCity,
  vendorList,
  vendorTagsList,
  filter: { tagId, from, to },
}) => {
  const variantSelector = useVariantByBreakpoint();

  const [loadedData, setLoadedData] = useState(12);

  const [filteredVendorList, setFilteredVendorList] = useState(vendorList);

  const vendorCards = useMemo(
    () => filteredVendorList.slice(0, loadedData),
    [loadedData, filteredVendorList]
  );

  const handleTagSelection = (tag) => {
    navigate(
      experiencePageUrl(strapiCity, {
        tagId: tag.id,
        from: from && new Date(`${from}T00:00:00.000`),
        to: to && new Date(`${to}T00:00:00.000`),
      })
    );
  };

  const handleDateSelection = (range) => {
    if (!range.start) return;
    navigate(
      experiencePageUrl(strapiCity, {
        tagId,
        from: range.start,
        to: range.end,
      })
    );
  };

  useEffect(() => {
    tagId &&
      setFilteredVendorList(
        filterVendorListByTagId(vendorList, tagId).filter(
          filterByExperienceLimit(from, to)
        )
      );
  }, [tagId, from, to]);

  const BREADCRUMB_LINKS = [
    { text: "Explore", to: "/" },
    {
      text: `${strapiCity.name}`,
      to: cityPageUrl(strapiCity),
    },
    {
      text: "Experiences",
      to: experiencePageUrl(strapiCity),
    },
  ];

  return (
    <Layout page={PAGE_NAME} breadcrumbs={BREADCRUMB_LINKS}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
          py: 10,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <Link
            to={cityPageUrl(strapiCity)}
            sx={{ variant: "link.text.small", color: "primary", mb: 1 }}
          >
            {strapiCity.name}
          </Link>
          <Heading
            variant={variantSelector(["heading5", "heading4", "heading3"])}
            sx={{ mb: 4 }}
          >
            Experiences in {strapiCity.name}
          </Heading>
          <Paragraph variant="body1" sx={{ color: "text.secondary", mb: 10 }}>
            Places to see, ways to wander, and signature experiences that define
            London.
          </Paragraph>
          {/* <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              display: ["none", "none", "block"],
            }}
          >
            BOOKING DETAILS PLACEHOLDER
          </Box> */}
        </Box>

        <DoubleDatePickerField
          sx={{
            maxWidth: 100,
          }}
          defaultValue={
            from && to
              ? formatDateForCalendar({
                  start: `${from}T00:00:00.000`,
                  end: `${to}T00:00:00.000`,
                })
              : undefined
          }
          input={
            <FilterInput
              name="date-filter"
              onSearch={handleDateSelection}
              rightIcon
            />
          }
        />
        <TagSlider
          sx={{
            mb: 10,
          }}
          tags={vendorTagsList}
          handleClick={handleTagSelection}
          tagId={tagId}
        />
      </Box>

      <Box sx={{ mb: 5 }}>
        <Box sx={{ display: "flex", pb: [6, 6, 4] }}>
          <Paragraph
            variant={variantSelector(["body2", "body1"])}
            sx={{ flex: "1" }}
          >
            {filteredVendorList.length} results
          </Paragraph>
          <Paragraph
            variant={variantSelector(["subtitle2", "subtitle1"])}
            sx={{ color: "primary", display: "flex", alignItems: "center" }}
          >
            <SortIcon sx={{ color: "primary" }} />
            <Box sx={{ display: ["none", "block"], pr: 2 }}>Sort by:</Box>
            Recommended
          </Paragraph>
        </Box>

        <TileList
          vendorList={vendorCards}
          loadedData={loadedData}
          setLoadedData={setLoadedData}
          totalCount={filteredVendorList.length}
        />
      </Box>
    </Layout>
  );
};

export default ExperiencesPage;
