import React from "react";
import Link from "components/Link";
import MenuSlider from "components/MenuSlider";

const TagSlider = ({ tags, handleClick, tagId, ...props }) => {
  return (
    <MenuSlider {...props}>
      {tags.map((tag) => (
        <Link
          key={`tag-link-${tag.id}`}
          className={tagId === tag.id ? "isActive" : ""}
          sx={{
            variant: "pills",
            width: "max-content",
            scrollSnapAlign: "center",
          }}
          onClick={handleClick && (() => handleClick(tag))}
        >
          {tag.title}
        </Link>
      ))}
    </MenuSlider>
  );
};

export default TagSlider;
