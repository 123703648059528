import React from "react";
import { graphql } from "gatsby";

import ExperiencesPage from "explore/ExperiencesPage";
import { filterByLimitedTime } from "utils/filterBy";
import SEO from "components/SEO";
import getImage from "utils/getImage";

export default function Experiences({ data, location, pageContext }) {
  const filterParams = new URLSearchParams(location.search);

  const filterBy = {
    tagId: parseInt(filterParams.get("vendorTagId")),
    from: filterParams.get("from"),
    to: filterParams.get("to"),
  };

  const strapiCity = {
    ...data.strapiCity,
    image: getImage(data.strapiCity.hero_image),
  };

  const vendorList = data.allStrapiVendor.nodes
    .filter(filterByLimitedTime)
    .map((node) => ({
      ...node,
      image: getImage(
        node.hero_image,
        data.strapiRecommendsConfig.defaultVendorImage
      ),
    }));

  const sortedVendorTags = pageContext.vendorTags.sort(
    (a, b) => b.count - a.count
  );

  return (
    <ExperiencesPage
      filter={filterBy}
      strapiCity={strapiCity}
      vendorList={vendorList}
      vendorTagsList={sortedVendorTags}
    />
  );
}

export const allStrapiVendors = graphql`
  query ExperiencesPageQuery($slug: String, $propertyIds: [Int]) {
    strapiCity(slug: { eq: $slug }) {
      id: strapi_id
      name
      slug
      hero_image {
        ...strapiMediaFragment
      }
    }

    allStrapiVendor(filter: { property_id: { in: $propertyIds } }) {
      nodes {
        slug
        axpVendorId: axp_vendor_id
        id: strapi_id
        display_name
        name
        description
        startsOn: starts_on
        endsOn: ends_on
        displayFrom: display_from
        expiresAt: expires_at
        contactInfo: contact_info {
          address {
            address_line_1
            city
            country
          }
        }
        hero_image {
          ...strapiMediaFragment
        }
        tags: vendor_tags {
          id: strapi_id
          title
        }
      }
      totalCount
    }

    strapiRecommendsConfig {
      defaultVendorImage: default_vendor_image {
        ...strapiMediaFragment
      }
    }
  }
`;

export const Head = () => <SEO title="Experiences" />;
